<template>
  <div>
    <section id="contentbox" class="container container-fluid">
      <div class="form-login form-horizontal" id="register">
        <div class="bg-dark text-white border border-secondary rounded px-3 py-2 mt-2">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <div class="indexlogo">
                <!-- <div class="logoindex">
                  <router-link to="welcome"><img src="@/assets/wisdom/logo wisdombet-03.png" class="p-0" style="" /></router-link>
                </div> -->
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-center text-sm-center text-md-right">
              <h3 class="mt-1 font-weight-light"><i class="fas fa-key"></i> ลืมรหัสผ่าน</h3>
            </div>
          </div>
        </div>
        <div class="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2">
          <h5 class="text-primary">
            <span class="badge badge-pill badge-primary font-weight-light">STEP 1</span>
            ระบุเบอร์โทร
          </h5>
          <div class="row">
            <div class="col-12 col-sm-12">
              <h6 class="text-dark"><i class="fas fa-mobile-alt"></i> ลืมรหัสผ่าน เพียงแค่ใส่เบอร์ที่เคยใช้สมัคร</h6>
            </div>
            <div class="col-4 col-sm-4 col-md-2 d-flex align-items-center justify-content-md-end">
              <span class="flag-icon flag-icon-th mr-1"></span> +66
            </div>
            <div class="col-8 col-sm-8 col-md-4">
              <input
                type="tel"
                name="tel"
                class="form-control telinput"
                maxlength="10"
                required=""
                style="
                  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAfBJREFUWAntVk1OwkAUZkoDKza4Utm61iP0AqyIDXahN2BjwiHYGU+gizap4QDuegWN7lyCbMSlCQjU7yO0TOlAi6GwgJc0fT/fzPfmzet0crmD7HsFBAvQbrcrw+Gw5fu+AfOYvgylJ4TwCoVCs1ardYTruqfj8fgV5OUMSVVT93VdP9dAzpVvm5wJHZFbg2LQ2pEYOlZ/oiDvwNcsFoseY4PBwMCrhaeCJyKWZU37KOJcYdi27QdhcuuBIb073BvTNL8ln4NeeR6NRi/wxZKQcGurQs5oNhqLshzVTMBewW/LMU3TTNlO0ieTiStjYhUIyi6DAp0xbEdgTt+LE0aCKQw24U4llsCs4ZRJrYopB6RwqnpA1YQ5NGFZ1YQ41Z5S8IQQdP5laEBRJcD4Vj5DEsW2gE6s6g3d/YP/g+BDnT7GNi2qCjTwGd6riBzHaaCEd3Js01vwCPIbmWBRx1nwAN/1ov+/drgFWIlfKpVukyYihtgkXNp4mABK+1GtVr+SBhJDbBIubVw+Cd/TDgKO2DPiN3YUo6y/nDCNEIsqTKH1en2tcwA9FKEItyDi3aIh8Gl1sRrVnSDzNFDJT1bAy5xpOYGn5fP5JuL95ZjMIn1ya7j5dPGfv0A5eAnpZUY3n5jXcoec5J67D9q+VuAPM47D3XaSeL4AAAAASUVORK5CYII=');
                  background-repeat: no-repeat;
                  background-attachment: scroll;
                  background-size: 16px 18px;
                  background-position: 98% 50%;
                "
                v-model="mobilePhone"
              />
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-right text-sm-right text-md-left">
              <small class="text-secondary">กรุณาใส่หมายเลขโทรศัพท์เพื่อยืนยันตัวตน</small>
            </div>
            <div class="col-12 border-bottom my-3"></div>
          </div>
        </div>

        <div class="bg-secondary border-dark shasow-sm rounded p-3 h-100 my-2 mb-5">
          <div class="row">
            <div class="col">
              <router-link to="/hotgame" tag="button" class="btn btn-dark btn-block">
                <i class="fas fa-chevron-left"></i> ย้อนกลับ
              </router-link>
            </div>
            <div class="col">
              <button @click="onSubmit" class="btn btn-primary btn-block">
                ถัดไป <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { checkNumber } from '@/utils/number'
import { submitForgetService } from '@/services'
export default {
  data () {
    return {
      mobilePhone: ''
    }
  },
  watch: {
    mobilePhone: function (value) {
      if (!checkNumber(value)) {
        this.mobilePhone = value.substring(0, value.length - 1)
      } else if (value.length === 1 && value !== '0') {
        this.mobilePhone = '0' + value
      }
      if (value.length > 10) {
        this.mobilePhone = value.substring(0, 10)
      }
    }
  },
  methods: {
    ...mapActions(['setLoading', 'setMobilePhone']),
    async onSubmit () {
      try {
        this.setLoading(true)
        const resp = await submitForgetService({ mobilePhone: this.mobilePhone })
        if (resp.status_code === 'SUCCESS') {
          this.setMobilePhone(this.mobilePhone)
          this.$router.replace('/forgot-verify')
          // this.$router.replace('/reset')
        }
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    }
  }
}
</script>

<style>
</style>
